import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

const CareerForm = (props) => {
  // console.log(props);
  const [t, i18n] = useTranslation("common");
  return (
    <ModalContainer show={props.show}>
      <Container>
        <Form autoComplete="off">
          <InputGroup>
            <Input
              type="input"
              name="name"
              value={props.state.name}
              placeholder={t("Full Name")}
              onChange={props.handleChange}
            />
            {props.state.errors.name && (
              <span className="error">{props.state.errors.name}</span>
            )}
          </InputGroup>
          <InputGroup>
            <Input
              type="input"
              name="email"
              value={props.state.email}
              placeholder={t("Email")}
              onChange={props.handleChange}
            />
            {props.state.errors.email && (
              <span className="error">{props.state.errors.email}</span>
            )}
          </InputGroup>
          <InputGroup>
            <Input
              type="input"
              name="phone"
              value={props.state.phone}
              placeholder={t("Phone Number")}
              onChange={props.handleChange}
            />
            {props.state.errors.phone && (
              <span className="error">{props.state.errors.phone}</span>
            )}
          </InputGroup>

          <InputGroup>
            <Input
              type="input"
              name="experience"
              value={props.state.experience}
              placeholder={t("Experience")}
              onChange={props.handleChange}
            />
            {props.state.errors.experience && (
              <span className="error">{props.state.errors.experience}</span>
            )}
          </InputGroup>
          <InputGroup>
            <TextArea
              type="textarea"
              name="coverletter"
              value={props.state.coverletter}
              placeholder={t("Cover Letter")}
              onChange={props.handleChange}
            ></TextArea>
            {props.state.errors.coverletter && (
              <span className="error">{props.state.errors.coverletter}</span>
            )}
          </InputGroup>
        </Form>
        <ButtonGroup>
          <LeftContainer>
            <UploadButtonWrapper>
              <Button
                className="upload-resume"
                onClick={() => props.setCareerForm({ show: false, id: 0 })}
              >
                {t("Upload Resume")}
              </Button>
              <input
                type="file"
                onChange={props.handleFileChange}
                name="resume"
              />
              {props.state.errors.resume && (
                <span className="error">{props.state.errors.resume}</span>
              )}
            </UploadButtonWrapper>
          </LeftContainer>
          <RightContainer>
            <Button
              className="cancel"
              onClick={() => props.setCareerForm({ show: false, id: 0 })}
            >
              {t("Cancel")}
            </Button>
            <Button className="apply" onClick={props.handleSubmit}>
              {t("Send")}
            </Button>
          </RightContainer>
        </ButtonGroup>
      </Container>
    </ModalContainer>
  );
};

export default CareerForm;

const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 10;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin: auto;
  transition: all 0.3s ease-in-out;
  ${({ show }) =>
    show &&
    `
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.2);
    visibility: visible;
    transition: all 0.3s ease-in-out;
  `}
`;

const Container = styled.div`
  width: 500px;
  height: fit-content;
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;

  z-index: 10;
  margin: auto;
`;

const ButtonGroup = styled.div`
  margin-top: 25px;
  text-align: right;
  display: flex;
  justify-content: space-between;
`;
const Button = styled.button`
  border: 0;
  padding: 10px 25px;
  color: #fff;
  background: #030303;
  border-radius: 3px;
  cursor: pointer;
  &.cancel {
    margin-right: 8px;
  }
  &.apply {
    background: #2fc742;
  }
  &.upload-resume {
    background: #f6f6f6;
    color: #000;
    border: 1px solid #ccc;
  }
`;
const Form = styled.form``;
const InputGroup = styled.div`
  span {
    color: red;
    margin-top: 5px;
    display: block;
  }
  margin-bottom: 10px;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 3px;
  background: #f6f6f6;
  outline: none;
  &::-webkit-input-placeholder {
    /* Edge */
    font-family: "poppinsregular";
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "poppinsregular";
  }

  &::placeholder {
    font-family: "poppinsregular";
  }
`;
const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 3px;
  background: #f6f6f6;
  outline: none;
  min-height: 100px;
  &::-webkit-input-placeholder {
    /* Edge */
    font-family: "poppinsregular";
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "poppinsregular";
  }

  &::placeholder {
    font-family: "poppinsregular";
  }
`;
const LeftContainer = styled.div``;
const RightContainer = styled.div``;
const UploadButtonWrapper = styled.div`
  span {
    display: block;
    text-align: left;
    margin-top: 5px;
    color: red;
  }
  position: relative;
  overflow: hidden;
  display: inline-block;
  & input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;
