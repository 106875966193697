import styled from "styled-components";
import Lottie from "react-lottie";
import animationData from "./../lottie/not-found.json";

const NotFound = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Container>
      <NotFoundPage>
        <Lottie
          options={defaultOptions}
          height={"300px"}
          width={"300px"}
          //   isStopped={this.state.isStopped}
          //   isPaused={this.state.isPaused}
        />
      </NotFoundPage>
    </Container>
  );
};

const Container = styled.main``;
const NotFoundPage = styled.div`
  width: 100%;
  padding: 5% 0;
  height: 60vh;
  div[role="button"] {
    margin: auto auto;
  }
  svg {
    width: 300px !important;
  }
`;

export default NotFound;
