import {
  AppBar,
  Box,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import styled from "styled-components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import * as base from "../settings";
import main_loader from "../lottie/mainloader.json";
import { HashLink } from "react-router-hash-link";
import Lottie from "react-lottie";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    // id: `full-width-tab-${index}`,
    // "aria-controls": `full-width-tabpanel-${index}`,
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#fff",
    // color: "#000",
    // fontSize: "25px",
    // width: "90%",
    // margin: "0 auto",
    // "& .MuiTabs-flexContainer": {
    //   backgroundColor: "#fff",
    // },
    // "& .MuiPaper-root": {
    //   boxShadow: "none",
    // },
    // "& .MuiTab-wrapper": {
    //   fontSize: "25px",
    //   fontWeight: "bold",
    //   color: "#000",
    // },
    // "& .PrivateTabIndicator-root-2.PrivateTabIndicator-colorPrimary-3.MuiTabs-indicator":
    //   {
    //     backgroundColor: "#da3c1e",
    //   },
  },
}));

const Portfolio = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: main_loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [state, setState] = useState({
    applications: [],
    graphic_designs: [],
    web_apps: [],
    web_designs: [],
    loading: true,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const application_response = await fetch(
        `${base.BASE_URL}/web/applications/`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const graphic_design_response = await fetch(
        `${base.BASE_URL}/web/graphic_designs/`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const web_app_response = await fetch(`${base.BASE_URL}/web/web_apps/`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      });
      const web_design_response = await fetch(
        `${base.BASE_URL}/web/web_designs/`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
        }
      );

      const application = await application_response.json();
      // console.log(application,'==application');
      const graphic_design = await graphic_design_response.json();
      const web_app = await web_app_response.json();
      const web_design = await web_design_response.json();
      setState({
        ...state,
        applications: application || [],
        graphic_designs: graphic_design || [],
        web_apps: web_app || [],
        web_designs: web_design || [],
        loading: false,
      });
    }
    fetchData();
  }, []);

  const [t, i18n] = useTranslation("common");

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  let language = i18n.language;

  if (state.loading) {
    return (
      <MainLoader>
        <Lottie options={defaultOptions} />
        <img src="/images/whitelogo.svg" alt="logo" />
      </MainLoader>
    );
  } else {
    return (
      <Container>
        <PortfolioSpotlight>
          <PortfolioHeading language={language}>
            {t("Portfolio")}
          </PortfolioHeading>
          <PortfolioContent language={language}>
            {t(
              "We Are A Full-Service, Digital Agency, Born To Ensure The Well-Being Of Your Whole Business. From Web & App Development To ERP Solution And Tax Auditing, We Offer A Full Suite Of Marketing Services.."
            )}
          </PortfolioContent>
          <ScrollDown>
            <HashLink to="#applications">
              <ScrollDownText language={language}>
                {t("Scroll Down")}
              </ScrollDownText>
              <ScrollDownArrow language={language}>
                <small className="icon-down-small"></small>
              </ScrollDownArrow>
            </HashLink>
          </ScrollDown>
        </PortfolioSpotlight>
        <TabList language={language} id="applications">
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                language={language}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                aria-label="full width tabs example"
              >
                <Tab
                  language={language}
                  label={t("Applications")}
                  {...a11yProps(0)}
                />
                <Tab
                  language={language}
                  label={t("Graphic Designs")}
                  {...a11yProps(1)}
                />
                <Tab
                  language={language}
                  label={t("Web Apps")}
                  {...a11yProps(2)}
                />
                <Tab
                  language={language}
                  label={t("Web Designs")}
                  {...a11yProps(3)}
                />
              </Tabs>
            </AppBar>
          </div>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanels value={value} index={0} dir={theme.direction}>
              {state.applications.length > 0 && (
                <Applications>
                  {state.applications.map((application, x = 1) => (
                    <ApplicationList key={x}>
                      <a
                        href={application.url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <ApplicationImage>
                          <img
                            src={base.MEDIA_URL + application.image}
                            alt="hifive"
                          />
                        </ApplicationImage>
                        <ApplicationHeading>
                          <ApplictionHeadingNumber>
                            0{x + 1}
                          </ApplictionHeadingNumber>
                          <ApplicationHeadingText>
                            {application.heading}
                          </ApplicationHeadingText>
                        </ApplicationHeading>
                        <ApplicationContent>
                          {application.content}
                        </ApplicationContent>
                      </a>
                    </ApplicationList>
                  ))}
                </Applications>
              )}
            </TabPanels>
            <TabPanels value={value} index={1} dir={theme.direction}>
              {state.graphic_designs.length > 0 && (
                <Applications>
                  {state.graphic_designs.map((graphic_design, x = 1) => (
                    <ApplicationList key={x}>
                      <a
                        href={graphic_design.url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <ApplicationImage>
                          <img
                            src={base.MEDIA_URL + graphic_design.image}
                            alt="hifive"
                          />
                        </ApplicationImage>
                        <ApplicationHeading>
                          <ApplictionHeadingNumber>
                            0{x + 1}
                          </ApplictionHeadingNumber>
                          <ApplicationHeadingText>
                            {graphic_design.heading}
                          </ApplicationHeadingText>
                        </ApplicationHeading>
                        <ApplicationContent>
                          {graphic_design.content}
                        </ApplicationContent>
                      </a>
                    </ApplicationList>
                  ))}
                </Applications>
              )}
            </TabPanels>
            <TabPanels value={value} index={2} dir={theme.direction}>
              {state.web_apps.length > 0 && (
                <Applications>
                  {state.web_apps.map((web_app, x = 1) => (
                    <ApplicationList key={x}>
                      <a href={web_app.url} rel="noreferrer" target="_blank">
                        <ApplicationImage>
                          <img
                            src={base.MEDIA_URL + web_app.image}
                            alt="hifive"
                          />
                        </ApplicationImage>
                        <ApplicationHeading>
                          <ApplictionHeadingNumber>
                            0{x + 1}
                          </ApplictionHeadingNumber>
                          <ApplicationHeadingText>
                            {web_app.heading}
                          </ApplicationHeadingText>
                        </ApplicationHeading>
                        <ApplicationContent>
                          {web_app.content}
                        </ApplicationContent>
                      </a>
                    </ApplicationList>
                  ))}
                </Applications>
              )}
            </TabPanels>
            <TabPanels value={value} index={3} dir={theme.direction}>
              {state.web_designs.length > 0 && (
                <Applications>
                  {state.web_designs.map((web_design, x = 1) => (
                    <ApplicationList key={x}>
                      <a href={web_design.url} rel="noreferrer" target="_blank">
                        <ApplicationImage>
                          <img
                            src={base.MEDIA_URL + web_design.image}
                            alt="hifive"
                          />
                        </ApplicationImage>
                        <ApplicationHeading>
                          <ApplictionHeadingNumber>
                            0{x + 1}
                          </ApplictionHeadingNumber>
                          <ApplicationHeadingText>
                            {web_design.heading}
                          </ApplicationHeadingText>
                        </ApplicationHeading>
                        <ApplicationContent>
                          {web_design.content}
                        </ApplicationContent>
                      </a>
                    </ApplicationList>
                  ))}
                </Applications>
              )}
            </TabPanels>
          </SwipeableViews>
        </TabList>
      </Container>
    );
  }
};

const MainLoader = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 20%;
  margin: 0 auto;
  div {
    width: 100% !important;
    height: 40% !important;
    padding-bottom: 15%;
  }
  svg path {
  }
  img {
    top: 6%;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 70px;
    margin: auto;
  }
  @media (max-width: 480px) {
    width: 10%;
    div {
      width: 70% !important;
      height: 70% !important;
      padding-bottom: 15%;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    div {
      width: 85% !important;
      height: 40% !important;
    }
  }
`;
const TabPanels = styled(TabPanel)`
  @media all and (max-width: 980px) {
    margin-bottom: 0%;
  }
  @media all and (max-width: 640px) {
    margin-bottom: 0%;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 3%;
  }
`;

const Container = styled.main`
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
`;
const PortfolioSpotlight = styled.section`
  width: 30%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
  position: relative;
  @media all and (max-width: 980px) {
    width: 50%;
  }
  @media all and (max-width: 640px) {
    width: 60%;
  }
  @media all and (max-width: 480px) {
    width: 70%;
    height: 90vh;
  }
`;
const PortfolioHeading = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  font-size: 95px;
  margin-bottom: 2%;
  font-weight: bold;
  &:before {
    position: absolute;
    content: "";
    top: 150px;
    right: 450px;
    border-radius: 200px;
    width: 200px;
    height: 200px;
    background: linear-gradient(
      60deg,
      rgba(138, 217, 232, 1) 20%,
      rgba(255, 255, 255, 1) 96%
    );
    -webkit-box-shadow: 37px -129px 67px 9px rgba(228, 244, 247, 0.72);
    -moz-box-shadow: 37px -129px 67px 9px rgba(228, 244, 247, 0.72);
    box-shadow: 37px -129px 67px 9px rgba(228, 244, 247, 0.72);
    filter: blur(30px);
    backdrop-filter: blur(60px);
    -webkit-backdrop-filter: blur(60px);
    z-index: -1;
    opacity: 0.7;
  }
  &:after {
    /* @keyframes MoveUpDown {
      from {
        top: 0;
        left: 0;
      }
      to {  
        top: 135px;
        left: 300px;
      }
    } */
    @keyframes MoveUpDown {
      0%,
      100% {
        top: 0;
        left: 300px;
        width: 100px;
        height: 100px;
      }
      50% {
        top: 50px;
        left: 50px;
        width: 150px;
        height: 150px;
      }
    }
    position: absolute;
    content: "";
    top: 50px;
    left: 0px;
    border-radius: 200px;
    width: 230px;
    height: 230px;
    background: linear-gradient(
      36deg,
      rgba(164, 246, 207, 0.25) 5%,
      rgba(247, 247, 247, 0.25) 82%
    );

    -webkit-box-shadow: 37px -129px 67px 9px rgba(228, 244, 247, 0.72);
    -moz-box-shadow: 37px -129px 67px 9px rgba(228, 244, 247, 0.72);
    box-shadow: 37px -129px 67px 9px rgba(228, 244, 247, 0.72);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: 1;
    animation: MoveUpDown 12s linear infinite;
  }
  @media all and (max-width: 1366px) {
    @keyframes MoveUpDown {
      0%,
      100% {
        top: unset;
        left: unset;
        width: 100px;
        height: 100px;
      }
      50% {
        top: unset;
        left: unset;
        bottom: unset;
        width: 130px;
        height: 130px;
      }
    }
    &:after {
      width: 130px;
      height: 130px;
    }
  }
  @media all and (max-width: 768px) {
    @keyframes MoveUpDown {
      0%,
      100% {
        top: 0;
        left: 0px;
        width: 20px;
        height: 20px;
      }
      50% {
        top: 0;
        left: 20px;
        width: 50px;
        height: 50px;
      }
    }
    &:after {
      top: 0;
      left: 0;
    }
  }
  @media all and (max-width: 640px) {
    &:after {
      top: unset;
      left: unset;
    }
  }
  @media all and (max-width: 480px) {
    font-size: 60px;
    &:before {
      top: unset;
      width: 50px;
      height: 50px;
      right: -100px;
    }
    &:after {
      top: unset;
      width: 50px;
      height: 50px;
      left: -100px;
    }
  }
`;
const PortfolioContent = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  line-height: 1.5em;
  font-size: 15px;
  /* margin-bottom: 20%; */
`;
const ScrollDown = styled.div`
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  @media all and (max-width: 480px) {
    bottom: 80px;
  }
  @media all and (max-width: 360px) {
    bottom: 60px;
  }
`;
const ScrollDownText = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  font-size: 15px;
  color: #555;
`;
const ScrollDownArrow = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  width: 33px;
  height: 33px;
  border: 2px solid #555;
  font-size: 25px;
  border-radius: 5px;
  small {
    color: #555;
  }
  small::before {
    margin: 0;
  }
`;
const TabList = styled.div`
  margin-bottom: 0;
  & .MuiPaper-root {
    background: #fff;
  }
  & .MuiTabs-root {
    width: 95%;
    margin: 0 auto;
    background: #fff;
  }
  .MuiBox-root-7 {
    padding: 24px 0;
  }
  /* & .MuiBox-root {
    width: 90%;
    margin: 0 auto;
  } */
  /* & .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.MuiTab-fullWidth span.MuiTab-wrapper:first-child{
    position: relative;
  }
  & .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.MuiTab-fullWidth span.MuiTab-wrapper:first-child:before{
    position: absolute;
    left: 0;
    top: 0;
    content: url('/images/vertical.png') ;
    background-size: 10px 20px;
    display: inline-block;
    width: 10px; 
    height: 20px;
    content:"";
  } */
  & .MuiTabs-flexContainer {
    background: #fff;
    justify-content: space-between;
  }
  & .MuiPaper-root {
    box-shadow: none;
  }
  & .MuiTab-wrapper {
    font-family: ${({ language }) =>
      language === "ar" ? "cairoregular" : "inherit"};
    font-size: 25px;
    font-weight: bold;
    color: #000;
  }
  &
    .PrivateTabIndicator-root-2.PrivateTabIndicator-colorPrimary-3.MuiTabs-indicator {
    background: #da3c1e;
  }
  @media all and (max-width: 1105px) {
    & .MuiTab-wrapper {
      font-size: 20px;
    }
  }
  @media all and (max-width: 980px) {
    & .MuiTab-wrapper {
      font-size: 16px;
    }
  }
  @media all and (max-width: 768px) {
    & .MuiTab-wrapper {
      font-size: 14px;
    }
  }
  @media all and (max-width: 480px) {
    & .MuiBox-root {
      padding: 0;
    }
  }
`;
const Applications = styled.div`
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 2%;

  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
    grid-template-columns: 48% 48%;
    grid-gap: 2%;
  }
  @media all and (max-width: 680px) {
    grid-template-columns: auto;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 0;
  }
`;
const ApplicationList = styled.div`
  padding: 10px;
  width: 100%;
  background: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 3%;
  a {
    color: #000;
  }
  &:hover {
    opacity: 0.7;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }
  @media all and (max-width: 1080px) {
    /* width: 32%; */
  }
  @media all and (max-width: 980px) {
    /* width: 48%; */
  }
  @media all and (max-width: 680px) {
    width: 100%;
  }
`;
const ApplicationImage = styled.div`
  img {
    border-radius: 10px;
    width: 100%;
    height: 195px;
    object-fit: cover;
  }
  margin-bottom: 2%;
`;
const ApplicationHeading = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
`;
const ApplictionHeadingNumber = styled.div`
  font-size: 60px;
  font-weight: bold;
  margin-right: 15px;
  -webkit-text-stroke: 2px #d8d8d8;
  -webkit-text-fill-color: #f5f5f5;
  @media all and (max-width: 480px) {
    font-size: 50px;
  }
  @media all and (max-width: 360px) {
    font-size: 40px;
  }
`;
const ApplicationHeadingText = styled.div`
  width: 80%;
  font-size: 30px;
  font-weight: bold;
  line-height: 1em;
  @media all and (max-width: 480px) {
    font-size: 30px;
  }
  @media all and (max-width: 360px) {
    font-size: 25px;
  }
`;
const ApplicationContent = styled.div``;
export default Portfolio;
