import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { HashLink } f  rom "react-router-hash-link";
import { useState } from "react";

const Header = () => {
  const [t, i18n] = useTranslation("common");
  let language = i18n.language;
  const [open, setOpen] = useState(false);
  return (
    <Nav>
      <Link to="/">
        <Logo>
          <img src="/images/vikncodes.svg" alt="vikncodes" />
        </Logo>
      </Link>

      <NavMenu open={open} language={language}>
        {/* <NavLink
          activeClassName="selected"
          onClick={() => setOpen(!open)}
          to="/services"
        >
          <span>Services</span>
        </NavLink> */}
        <NavLink
          activeClassName="selected"
          onClick={() => setOpen(!open)}
          to="/portfolio"
        >
          <span>{t("Portfolio")}</span>
        </NavLink>
        {/* <NavLink
          activeClassName="selected"
          onClick={() => setOpen(!open)}
          to="/products"
        >
          <span>{t("Products")}</span>
        </NavLink> */}
        <NavLink
          onClick={() => setOpen(!open)}
          // rel="noreferrer"
          to="/products"
        >
          <span>{t("Products")}</span>
        </NavLink>
        {/* <HashLink
          onClick={() => setOpen(!open)}
          // rel="noreferrer"
          to="/#products"
        >
          <span>{t("Products")}</span>
        </HashLink> */}
        <NavLink
          activeClassName="selected"
          onClick={() => setOpen(!open)}
          to="/about"
        >
          <span>{t("About")}</span>
        </NavLink>
        <NavLink
          activeClassName="selected"
          onClick={() => setOpen(!open)}
          to="/career"
        >
          <span>{t("Career")}</span>
        </NavLink>
        <NavLink
          activeClassName="selected"
          onClick={() => setOpen(!open)}
          to="/contact"
        >
          <span>{t("Contact")}</span>
        </NavLink>
      </NavMenu>
      <SignOut>
        <p>{i18n.language === "en" ? "English" : "Arabic"}</p>
        <DropDown>
          <span
            onClick={() => {
              i18n.changeLanguage("ar");
            }}
          >
            Arabic
          </span>
          <span
            onClick={() => {
              i18n.changeLanguage("en");
            }}
          >
            English
          </span>
        </DropDown>
      </SignOut>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
    </Nav>
  );
};

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 36px;
  letter-spacing: 16px;
  z-index: 3;
  box-shadow: 0px 2px 20px 0px rgb(0 0 0 / 10%);
`;
const Logo = styled.div`
  padding: 0;
  width: 120px;
  margin-top: 4px;
  max-height: 70px;
  font-size: 0;
  display: inline-block;
  img {
    display: block;
    width: 100%;
  }
`;
const NavMenu = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: flex-end;
  margin: 0px;
  padding: 0px;
  position: relative;
  .selected {
    transform-origin: left center;
    background: #ded7d7;
    padding: 5px 10px;
    border-radius: 50px;
    border: 0;
    box-sizing: border-box;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  }
  a {
    display: flex;
    align-items: center;
    padding: 0 12px;
    padding: 5px 10px;
    border-radius: 0;
    img {
      height: 20px;
      min-width: 20px;
      width: 20px;
      z-index: auto;
    }
    span {
      font-family: ${({ language }) =>
        language === "ar" ? "cairoregular" : "inherit"};
      color: #000;
      font-size: 13px;
      letter-spacing: 1.42px;

      line-height: 1.08;
      padding: 2px 0;
      white-space: nowrap;
      position: relative;
      padding: 5px 10px;
      box-sizing: border-box;
      font-weight: bold;
    }
    &:hover {
      transform-origin: left center;
      background: #f1f1f1;
      padding: 5px 10px;
      border-radius: 50px;
      border: 0;
      box-sizing: border-box;
      transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

      span:before {
        transform: scaleX(1);
        visibility: visible;
        opacity: 1 !important;
      }
    }
  }
  @media (max-width: 768px) {
    z-index: 50;
    flex-flow: column nowrap;
    background: #fff;
    position: fixed;
    transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
    opacity: ${({ open }) => (open ? 1 : 0)};
    top: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    /* padding-top: 7.5rem; */
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    align-items: center;

    a span {
      color: #000;
      font-weight: bold;
      align-items: center;
      /* letter-spacing: 10px; */
      letter-spacing: ${({ language }) => (language === "ar" ? "0" : "10px")};
      line-height: 4px;
      font-weight: bold;
      /* font-size: 20px; */
      font-size: ${({ language }) => (language === "ar" ? "35px" : "20px")};
      align-items: center;
      padding: 20px;
    }
    a:first-child {
      display: inherit;
    }
  }
`;

const UserImg = styled.img`
  height: 100%;
`;
const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 48px;
  background: rgb(19, 19, 19);
  border: 1px solid rgba(151, 151, 151, 0.34);
  border-radius: 4px;
  box-shadow: rgb(0, 0, 0/50%) 0px 0px 18px;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 3px;
  width: 100px;
  opacity: 0;
  /* display: none; */
  color: #fff;
  span {
    padding: 10px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  span:hover {
    background: #fff;
    color: #000;
  }
`;
const SignOut = styled.div`
  position: relative;
  height: 48px;
  width: 48px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${UserImg} {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  p {
    font-weight: bold;
    letter-spacing: 0em;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  p:before {
    position: absolute;
    content: "";
    right: -13px;
    top: 6px;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  ${DropDown} {
    opacity: 0.5;
    display: none;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }
  &:hover {
    ${DropDown} {
      opacity: 1;
      display: flex;
      transform-origin: 1px;
      transition: all 0.3s linear;
    }
  }
  @media (max-width: 768px) {
    right: 40px;
  }
`;
const StyledBurger = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 12px;
  right: 20px;
  cursor: pointer;

  z-index: 51;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    div {
      width: 1.5rem;
      height: 0.1rem;
      background: ${({ open }) => (open ? "#000" : "#000")};
      border-radius: 10px;
      transform-origin: 1px;
      transition: all 0.3s linear;
      &:nth-child(1) {
        transform: ${({ open }) => (open ? "rotate(47deg)" : "rotate(0)")};
      }
      &:nth-child(2) {
        transform: ${({ open }) =>
          open ? "translateX(100%)" : "translateX(0)"};
        opacity: ${({ open }) => (open ? 0 : 1)};
      }
      &:nth-child(3) {
        transform: ${({ open }) => (open ? "rotate(-47deg)" : "rotate(0)")};
      }
    }
  }
`;
export default Header;
