import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import About from "./containers/About";
import Contact from "./containers/Contact";
import Portfolio from "./containers/Portfolio";
import Home from "./containers/Home";
import Services from "./containers/Services";
import Footer from "./components/Footer";
import NotFound from "./containers/NotFound";
import Lottie from "react-lottie";
import React, { useState } from "react";
import main_loader from "./lottie/mainloader.json";
import styled from "styled-components";
import Products from "./containers/Products";
import Career from "./containers/Career";

function App() {
  let splashscreen = sessionStorage.getItem("splashscreen") || true;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: main_loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [state, setState] = useState(splashscreen);
  React.useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      setTimeout(() => setState(false), 3000);
      sessionStorage.setItem("splashscreen", false);
    }, 4000);

    return () => window.clearTimeout(timeoutID);
  }, []);
  if (state === true) {
    return (
      <MainLoader>
        <Lottie options={defaultOptions} />
        <img src="/images/whitelogo.svg" alt="logo" />
      </MainLoader>
    );
  } else {
    return (
      <div className="App">
        <Router>
          <Header />
          <Switch>
            {/* <Route exact path="/" component={Login} /> */}
            <Route exact path="/" component={Home} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/career" component={Career} />

            <Route component={NotFound} />
          </Switch>
          <Footer />
        </Router>
      </div>
    );
  }
}
const MainLoader = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 20%;
  margin: 0 auto;
  div {
    width: 100% !important;
    height: 40% !important;
    padding-bottom: 15%;
  }
  svg path {
  }
  img {
    top: 6%;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 70px;
    margin: auto;
  }
  @media (max-width: 480px) {
    width: 10%;
    div {
      width: 70% !important;
      height: 70% !important;
      padding-bottom: 15%;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    div {
      width: 85% !important;
      height: 40% !important;
    }
  }
`;

export default App;
