import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const DownloadButton = (props) => {
  const [t, i18n] = useTranslation("common");
  return (
    <Container background={props.background} href={props.link} target="_blank">
      <IconImg src={props.image} />
      <DownloadText>{t(props.text)}</DownloadText>
    </Container>
  );
};

export default DownloadButton;

const Container = styled.a`
  background: #2f8cc9;
  ${({ background }) =>
    background &&
    `
    background: ${background};
    `}
  width: 140px;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 50px;
  cursor: pointer;
  img {
    border-radius: initial !important;
  }
  @media all and (max-width: 460px) {
    padding: 5px 10px;
    width: 130px;
  }
`;

const IconImg = styled.img`
  margin-right: 10px;
  border-radius: none !important;
  width: 20px;
  margin-right: 10px;
  @media all and (max-width: 480px) {
    width: 20px !important;
  }
`;
const DownloadText = styled.p`
  color: #fff;
  font-weight: bold;
  @media all and (max-width: 480px) {
    font-size: 12px;
  }
`;
