import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import swal from "sweetalert";
import CareerDescription from "../components/CareerDescription";
import CareerForm from "../components/CareerForm";
import * as base from "../settings";

const Career = () => {
  const [t, i18n] = useTranslation("common");
  const [showCareerDescription, setCareerDescription] = useState({
    show: false,
    id: 0,
  });
  const [showCareerForm, setCareerForm] = useState({
    show: false,
    id: 0,
  });
  const handleSubmit = () => {
    let is_valid = handleValidation();
    var formData = new FormData();
    formData.append("name", state.name);
    formData.append("email", state.email);
    formData.append("phone", state.phone);
    formData.append("cover_letter", state.coverletter);
    formData.append("experience", state.experience);
    formData.append("description", state.description);
    formData.append("resume", state.resume);
    formData.append(
      "designation",
      state.career_datas.filter((i) => i.id === showCareerForm.id)[0]
        .designation
    );
    // console.log(formData);
    if (is_valid) {
      axios
        .post(`${base.BASE_URL}/web/create-employee/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          if (response.data !== null && response.data !== undefined) {
            swal({
              title: response.data.title,
              text: response.data.message,
              icon: "success",
              button: true,
            });
            setState({
              ...state,
              name: "",
              email: "",
              phone: "",
              coverletter: "",
              experience: "",
              description : "",
              resume: "",
              errors: {},
            });
            setCareerForm({ show: false, id: 0 });
            setCareerDescription({ show: false, id: 0 });
          }
        });
    }
  };
  const [state, setState] = useState({
    career_datas: [],
    name: "",
    message: "",
    phone: "",
    email: "",
    errors: {
      name: "",
      message: "",
      phone: "",
      email: "",
    },
  });
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.files[0] });
  };
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    //Name
    if (!state.name) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    } else if (typeof state.name !== "undefined") {
      if (!state.name.match(/^[a-zA-Z\s]*$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    }
    //experience
    if (!state.experience) {
      formIsValid = false;
      errors["experience"] = "Cannot be empty";
    }
    //coverletter
    if (!state.coverletter) {
      formIsValid = false;
      errors["coverletter"] = "Cannot be empty";
    }

    //resume
    if (!state.resume) {
      formIsValid = false;
      errors["resume"] = "Cannot be empty";
    }

    //Phone
    if (!state.phone) {
      formIsValid = false;
      errors["phone"] = "Cannot be empty";
    } else if (typeof state.phone !== "undefined") {
      if (state.phone.length < 10) {
        formIsValid = false;
        errors["phone"] = "Please enter a valid phone number";
      }
    }

    //Email
    if (!state.email) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    } else if (typeof state.email !== "undefined") {
      let lastAtPos = state.email.lastIndexOf("@");
      let lastDotPos = state.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }

    setState({ ...state, errors: errors });
    return formIsValid;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setState({
      ...state,
      name: "",
      email: "",
      phone: "",
      coverletter: "",
      experience: "",
      description: "",
      resume: "",
      errors: {},
    });
  }, [showCareerForm.show === false]);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${base.BASE_URL}/web/careers/`)
        .then(function (response) {
          // handle success
          setState({
            ...state,
            career_datas: response.data || [],
          });
        });
    };
    fetchData();
  }, []);
  return (
    <Container>
      <CareerContainer>
        <JoinUsContainer>
          <CareerHeading>{t("Career")}</CareerHeading>
          <ViknCodesAt>@Vikn Codes</ViknCodesAt>
          <JoinText>
            {t("Join Us")}
            <br />
            {t("In Our Journey")}
          </JoinText>
        </JoinUsContainer>
        <TalentContainer>
          <TalentText>{t("We're Always On The Look For Talent")}</TalentText>
          <TalentCaption>{t("Send You Resume To")}</TalentCaption>
          <HrMail href="mailto:hr@vikncodes.com">hr@vikncodes.com</HrMail>
          <CallUsContainer>
            <span>{t("Call Us")} - </span>
            <a href="tel:+919037444900">+91 9037 444 900</a>
          </CallUsContainer>
        </TalentContainer>
      </CareerContainer>
      {state.career_datas.length > 0 && [
        <VacanciesHeading>{t("Vacancies")}</VacanciesHeading>,
        <CareerListContainer>
          {state.career_datas.map((i) => (
            <CareerList>
              <CareerDetails
                onClick={() => setCareerDescription({ show: true, id: i.id })}
              >
                <CareerDesignation>
                  <span>{i.designation}</span>  {i.designation_name}
                </CareerDesignation>
                <CareerDesignation className="experience">
                  <span>Experience:</span> - {i.experience}
                </CareerDesignation>
              </CareerDetails>
              <ApplyButton
                onClick={() => setCareerForm({ show: true, id: i.id })}
              >
                {t("Apply")}
              </ApplyButton>
            </CareerList>
          ))}
        </CareerListContainer>,
      ]}
      <CareerForm
        handleChange={handleChange}
        handleFileChange={handleFileChange}
        show={showCareerForm.show}
        setCareerForm={setCareerForm}
        handleSubmit={handleSubmit}
        state={state}
      />
      {state.career_datas.length > 0 && (
        <CareerDescription
          career_datas={state.career_datas}
          id={showCareerDescription.id}
          show={showCareerDescription.show}
          setCareerForm={setCareerForm}
          setCareerDescription={setCareerDescription}
          // style={{ backgroundColor: state.career_datas.length > 0 ? 'black !important' : 'yellow !important' }}
        /> 
      )} 
    </Container>
  );
};

export default Career;

const Container = styled.div`
  padding-top: 100px;
  width: 80%;
  margin: 0 auto;
`;
const CareerContainer = styled.div`
  display: flex;
  margin-bottom: 28px;
  @media (max-width: 768px) {
    display: block;
  }
`;
const CareerHeading = styled.p`
  font-size: 35px;
  font-weight: bold;
  line-height: 30px;
`;
const ViknCodesAt = styled.p`
  font-size: 18px;
`;
const JoinText = styled.p`
  color: #726f6f;
  font-size: 14px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
const JoinUsContainer = styled.div`
  justify-content: space-between;
  margin-right: 100px;
`;
const TalentContainer = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  padding-right: 150px;
  @media all and (max-width: 768px) {
    padding-right: 55px;
  }
`;
const TalentText = styled.p`
  font-weight: bold;
  font-size: 20px;
`;
const TalentCaption = styled.p`
  font-size: 16px;
  color: #868686;
`;
const HrMail = styled.a`
  color: #323292;
  font-size: 16px;
  text-decoration: underline;
  margin-bottom: 10px;
  display: block;
`;
const CallUsContainer = styled.div`
  span {
    color: #008642;
  }
  a {
    color: #000;
  }
`;
const VacanciesHeading = styled.p`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
`;
const CareerListContainer = styled.div`
  height: 600px;
  margin-bottom: 20px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const CareerList = styled.div`
  position: relative;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 460px) {
    flex-direction: column;
  }
`;
const CareerDesignation = styled.p`
  color: #7c7c7c;
  font-size: 18px;
  span {
    color: #1c3766;
  }
  &.experience span {
    color: #000;
  }
`;
const CareerDetails = styled.div`
  cursor: pointer;
  width: 80%;
`;
const ApplyButton = styled.button`
  background: #0d4d29;
  border: 0;
  color: #fff;
  padding: 10px 30px;
  border-radius: 3px;
  height: fit-content;
  margin: auto 0;
  cursor: pointer;
  outline: none;
  @media all and (max-width: 460px) {
    margin-top: 10px;
  }
`;
