import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { useEffect } from "react";

const Home = (props) => {
  const [t, i18n] = useTranslation("common");
  let language = i18n.language;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Spotlight>
        <Heading language={language}>{t("we help business stand out")}</Heading>
        <Caption language={language}>
          {t("By creating Stunning")} <b>{t("visual identities")}</b> {t("and")}{" "}
          <b>{t("awesome websites")}.</b>
        </Caption>
        <Content language={language}>
          {t(
            "We Are A Full-Service, Digital Agency, Born To Ensure The Well-Being Of Your Whole Business. From Web & App Development To ERP Solution And Tax Auditing, We Offer A Full Suite Of Marketing Services..."
          )}
        </Content>
      </Spotlight>
      <Mission>
        <MissionContent language={language}>
          <p>
            {t(
              "Our Mission is to help Every person And Organisation in the planet to Achieve Greatness."
            )}
          </p>
          <MissionPlus>
            <img src="images/cursorplus.svg" alt="cursorplus" />
          </MissionPlus>
        </MissionContent>
        <Discover>
          <Link to="/Portfolio">
            <Portfolio>
              <PortfolioHeading language={language}>
                {t("Portfolio")}
              </PortfolioHeading>
              <DiscoverCaption language={language}>
                {t("Discover")}
              </DiscoverCaption>
              <PortfolioArrow>
                <img src="images/cursorarrow.svg" alt="cursorarrow" />
              </PortfolioArrow>
            </Portfolio>
          </Link>
          <HashLink
            // rel="noreferrer"
            to="#products"
          >
            <Product>
              <ProductHeading language={language}>
                {t("Products")}
              </ProductHeading>
              <DiscoverCaption language={language}>
                {t("Discover")}
              </DiscoverCaption>
              <ProductArrow>
                <img src="images/cursorarrow.svg" alt="cursorarrow" />
              </ProductArrow>
            </Product>
          </HashLink>
        </Discover>
      </Mission>
      <Specialized>
        <SpecializedHeading language={language}>
          {t("We're Specialized In")}
        </SpecializedHeading>

        <SpecializedContent language={language}>
          {t(
            "By Combining People, Creativity, And Technology, We Help Deliver The Most Innovative Web Solutions For Businesses. No Matter Which Service You Choose, The Idea Behind Our Approach Is Always The Same – To Help You Simplify The Process Of Getting Your Dreams Into Reality."
          )}
        </SpecializedContent>
      </Specialized>
      <Services>
        <ServiceImage>
          <img src="images/laptop.png" alt="laptop" />
        </ServiceImage>
        <ServiceContainer>
          <ServiceLeft>
            <AppDevelopment>
              <ServiceHeading language={language}>
                {t("App Development")}
              </ServiceHeading>
              <ServiceContent language={language}>
                {t(
                  "We Have A Team Of Expert Mobile Developers Who Proactively Cognize About Client Requirements And Market Trends To Build The Most Meaningful And Efficient Mobile Application For You And Your Customers With An Eye-Catching User Interface And Breathtaking User Experience."
                )}
              </ServiceContent>
            </AppDevelopment>
            <GraphicDesigning>
              <ServiceHeading language={language}>
                {t("Graphic Designing")}
              </ServiceHeading>
              <ServiceContent language={language}>
                {t(
                  "Here At Vikncodes, We Provide 360-Degree Promotions, Marketing, And Branding. We Are Experts In Implementing Technologies And Trends To Ensure Your Place In The Market In All Relevant Fields With All Our Marketing Team And Designers Working Hand In Hand To Find The Best Solution."
                )}
              </ServiceContent>
            </GraphicDesigning>
          </ServiceLeft>
          <ServiceRight>
            <WebDesigning>
              <ServiceHeading language={language}>
                {t("Web Designing")}
              </ServiceHeading>
              <ServiceContent language={language}>
                {t(
                  "Here At Vikncodes We Design Top Quality Websites That Will Be A Sure Asset For Your Business And Brand Concepts, Thus Creating A Better Brand Impression And User-Friendly Interface. We Create Visually Appealing, Responsive Designs Keeping Clients Needs In Mind With Every Creative Idea That We Have."
                )}
              </ServiceContent>
            </WebDesigning>
            <WebDevelopment>
              <ServiceHeading language={language}>
                {t("Web Development")}
              </ServiceHeading>
              <ServiceContent language={language}>
                {t(
                  "Vikncodes Have Mastered The Art Of Creating Top-Notch Websites And Web Solutions, All With Modern Cutting Edge Technologies To Make Your Business Stay Relevant For The Years To Come. We Provide Our Service With The Utmost Professional Accuracy And Teamwork To Ensure The Complete Satisfaction For You And Your Customers"
                )}
              </ServiceContent>
            </WebDevelopment>
            <ErpSolutions>
              <ServiceHeading language={language}>
                {t("ERP Solutions")}
              </ServiceHeading>
              <ServiceContent language={language}>
                {t(
                  "ViknERP and ViknBooks is all you will ever need as an ERPsoftware, but for those clients who are looking for customs solutions, our expert team can build ERP software from scratch that meets all your requirements and expectation to provide you a user experience that handmade just for you."
                )}
              </ServiceContent>
            </ErpSolutions>
          </ServiceRight>
        </ServiceContainer>
      </Services>
      {/* <OurProducts id={"products"}>
        <OurProductHeading language={language}>
          {t("Our Products")}
        </OurProductHeading>
        <ViknProduct>
          <ViknErpContainer>
            <ViknErp>
              <ViknErpLogo language={language}>
                <img src="images/viknerp.png" alt="viknerp" />
                <p>
                  {t(
                    "ViknERP Is An In-Depth ERP Solution For Any Organization With A Plathora Of Functionalities."
                  )}
                </p>
                <LearnMore language={language}>
                  <a href="!#" rel="noreferrer">
                    <p>{t("Learn More")}</p>
                    <img src="images/cursorarrow.svg" alt="cursorarrow" />
                  </a>
                </LearnMore>
              </ViknErpLogo>
            </ViknErp>
            <ViknErpWork>
              <img src="images/laptop2.png" alt="laptop" />
            </ViknErpWork>
          </ViknErpContainer>
          <ViknbooksContainer>
            <ViknbooksWork>
              <img src="images/laptop3.png" alt="laptop" />
            </ViknbooksWork>
            <ViknBooks>
              <ViknbooksLogo language={language}>
                <img src="images/viknbooks.svg" alt="viknbooks" />
                <p>
                  {t(
                    "Viknbooks Is An Online ERP Solution With All The Bells And Whistlesof Viknerp Sharing The Same Backbone To Ensure That You'll Stay Connected 24/7."
                  )}
                </p>
                <LearnMore language={language}>
                  <a
                    href="https://www.viknbooks.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>{t("Learn More")}</p>
                    <img src="images/cursorarrow.svg" alt="cursorarrow" />
                  </a>
                </LearnMore>
              </ViknbooksLogo>
            </ViknBooks>
          </ViknbooksContainer>
        </ViknProduct>
        <Rassasy>
          <RassasyLogo language={language}>
            <RassasyContent>
              <img src="images/rassasy.png" alt="rassasy" />
              <p>
                {t(
                  "Rassasy Helps You Transform A Handful Of Product Images And Your Product Name Into A Complete, Professional Digital Catelog In A Matter Of Minutes."
                )}
              </p>
              <LearnMore language={language}>
                <a
                  href="https://rassasy.vikncodes.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p>{t("Learn More")}</p>
                  <img src="images/cursorarrow.svg" alt="cursorarrow" />
                </a>
              </LearnMore>
            </RassasyContent>
          </RassasyLogo>
          <RassasyWork>
            <img src="images/rassasy_mobile.png" alt="laptop" />
          </RassasyWork>
        </Rassasy>
      </OurProducts> */}
      <ProductHightLight>
        <ProductContainer>
          <ProductImage src="images/rassasy-new.png" />
          <ProductDescription>
            <ProductLogo src="images/rassasylogo.svg" />
            <ProductHeading>{t("Restuarant POS")}</ProductHeading>
            <ProductContent>
              {t("Rassasy's Intuitive User interface elevates the user experience and makes billing fast and Intuitive. it is a feature-rich restaurant POS while being exceptionally user-friendly and eye-catching at the same time.")}
            </ProductContent>
            <TryNowButton
              href="https://play.google.com/store/apps/details?id=com.rassasy.erp"
              target="_blank"
            >
              {t("Try Now")}
            </TryNowButton>
          </ProductDescription>
        </ProductContainer>
      </ProductHightLight>
      <AboutUs>
        <TimeAndMoney>
          <TimeAndMoneyHeading language={language}>
            {t("Saving You Time And Money")}
          </TimeAndMoneyHeading>
          <TimeAndMoneyCaption language={language}>
            {t("streamlining internal processes")}
          </TimeAndMoneyCaption>
          <TimeAndMoneyContent language={language}>
            {t(
              "Initially We Thought Our Website Was Going Be Just To Validate Us As A Company. But What It Did Actually Was Save A Bunch Of Time By Implementing Different Tools That We Didn’t Even Know We Could Use A Website For."
            )}
          </TimeAndMoneyContent>
          <ContentAuthor>{t("Shaheen K K")}</ContentAuthor>
        </TimeAndMoney>
        <Reliable>
          <ReliableHeading language={language}>
            {t(
              "Vikn Codes LLP is a reliable and professional partner capable of solving your needs"
            )}
          </ReliableHeading>
          <ReliablePointsContainer>
            <ReliablePoints language={language}>
              <PointNumber>01</PointNumber>
              <PointText language={language}>
                {t(
                  "Strong Technical Skill To Solve Complex Tasks In The Best Way."
                )}
              </PointText>
            </ReliablePoints>
            <ReliablePoints language={language}>
              <PointNumber>02</PointNumber>
              <PointText language={language}>
                {t("Experienced Staffs In All Of The Field Related.")}
              </PointText>
            </ReliablePoints>
            <ReliablePoints language={language}>
              <PointNumber>03</PointNumber>
              <PointText language={language}>
                {t("We Have The Best Practices And Knowledge Bese Needed.")}
              </PointText>
            </ReliablePoints>
            <ReliablePoints language={language}>
              <PointNumber>04</PointNumber>
              <PointText language={language}>
                {t("Flexibility And Ease Of Decision Making. We Move Fast.")}
              </PointText>
            </ReliablePoints>
          </ReliablePointsContainer>
        </Reliable>
      </AboutUs>
      <Quotes>
        <QuoteLeft>
          <QuoteLeftContainer>
            <QuoteText language={language}>
              {t(
                "As A Trusted Advisor, We Are Working With Hundreds Of Our Clients Like You. We Understand Their Problems, Issues And Challenges."
              )}
            </QuoteText>
            <QuoteText language={language}>
              {t(
                "Our Commitment Is To Help Transforming Businesses Into The Edge Of IT And Embrace All Those New And Great Technologies"
              )}
            </QuoteText>
            <QuoteText language={language}>
              {t(
                "And Once Implemented, These Services Will Allow To Do More With Less While Staying Secure, Mobile And Compliant"
              )}
            </QuoteText>
          </QuoteLeftContainer>
          <GetInTouch>
            <Link to="contact">
              <GetInTouchHeading language={language}>
                {t("Get In Touch")}
              </GetInTouchHeading>
              <GetInTouchCaption language={language}>
                {t("Contact Us")}
              </GetInTouchCaption>
            </Link>
          </GetInTouch>
        </QuoteLeft>
        <QuoteRight>
          <QuoteContainerLeft>
            <QuoteContentOne>
              <QuoteContentText language={language}>
                {t(
                  "Please Make Sure That You Thank Everyone At Vikn Codes For All Of The Work They Have Done, We Are Very Happy With Everything And Will Be Recommending Your Services To Anyone That Will Listen. Pass It On Please."
                )}
              </QuoteContentText>
              <QuoteContentAuthor language={language}>
                {t("Savad Farooque K M")}
              </QuoteContentAuthor>
            </QuoteContentOne>
            <QuoteContentTwo>
              <QuoteContentText language={language}>
                {t(
                  "Vikn Codes Is Simply The Best Web Design Company You Can Work With. They Really Know Their Stuff. They Are Friendly And Have The Latest In Technology Trends To Keep Your Business Website Current And The Turnaround Time Is Incredible."
                )}
              </QuoteContentText>
              <QuoteContentAuthor language={language}>
                {t("Suhaib K")}
              </QuoteContentAuthor>
            </QuoteContentTwo>
            <QuoteContentThree>
              <QuoteContentText language={language}>
                {t(
                  "We Just Completed Our New Website With Vikn Codes Web Design And Are So Excited To Have It Live!! It Is So Professionally Done And Eye Appealing, Not To Mention How Great It Looks And Works With Mobile Devices."
                )}
              </QuoteContentText>
              <QuoteContentAuthor language={language}>
                {t("Jasmal T K")}
              </QuoteContentAuthor>
            </QuoteContentThree>
          </QuoteContainerLeft>
          <QuoteContainerRight>
            <QuoteContentFour>
              <QuoteContentText language={language}>
                {t(
                  "When You Choose Vikn Codes You Get A Wonderful, Professional Team With Innovative Ideas, Awesome Customer Service, And Exactly What You’re Looking For. Vikn Codes Took The Ideas That We Had And Put Them Perfectly On The Web."
                )}
              </QuoteContentText>
              <QuoteContentAuthor language={language}>
                {t("Uvais T")}
              </QuoteContentAuthor>
            </QuoteContentFour>
            <QuoteImage>
              <img src="images/hifive.png" alt="hifive" />
            </QuoteImage>
            <QuoteContentFive>
              <QuoteContentText language={language}>
                {t(
                  "Responsive And Timely In Getting Back To Me If I Have Questions Or Need To Make Changes. I Love Their Design Sense, And Their Willingness To Dig Down Deep To Understand My Business And What’s Important To Me And The People I Need To Reach. I’m So Thankful I Found Vikn Codes!"
                )}
              </QuoteContentText>
              <QuoteContentAuthor language={language}>
                {t("Rabeeh A M K")}
              </QuoteContentAuthor>
            </QuoteContentFive>
          </QuoteContainerRight>
        </QuoteRight>
      </Quotes>
    </Container>
  );
};

const Container = styled.main`
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
`;
const Spotlight = styled.section`
  margin-top: 10%; ;
`;

const Caption = styled.p`
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-left: ${({ language }) => (language === "ar" ? "auto" : "0")};
  font-family: ${({ language }) =>
    language === "ar" ? "cairobold" : "inherit"};
  margin-bottom: 25px;
  b {
    position: relative;
    font-weight: bold;
  }
  b:before {
    position: absolute;
    right: 0px;
    background-color: red;
    border-radius: 0 0 0 0;
    bottom: -4px;
    content: "";
    left: 0px;
    height: 1px;
  }
`;
const Content = styled.section`
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-left: ${({ language }) => (language === "ar" ? "auto" : "0")};
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  /* font-size: ${({ language }) =>
    language === "ar" ? "15px" : "inherit"}; */
  width: 500px;
  line-height: 1.5em;
  @media all and (max-width: 520px) {
    width: 90%;
  }
`;
const Heading = styled.h2`
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-left: ${({ language }) => (language === "ar" ? "auto" : "0")};
  font-family: ${({ language }) =>
    language === "ar" ? "cairobold" : "inherit"};

  line-height: 1.2em;
  font-weight: bold;
  width: 1000px;
  font-size: 100px;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-image: linear-gradient(
    27deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(37, 37, 41, 0.43461134453781514) 2%,
    rgba(0, 0, 0, 1) 100%
  );
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-transform: capitalize;
  @media all and (max-width: 980px) {
    font-size: 70px;
    width: 90%;
  }
  @media all and (max-width: 768px) {
    font-size: 50px;
    width: 90%;
  }
  @media all and (max-width: 680px) {
    font-size: 40px;
    width: 70%;
  }
  @media all and (max-width: 512px) {
    font-size: 40px;
    width: 90%;
    margin-top: 15%;
  }
  @media all and (max-width: 480px) {
    font-size: 30px;
    width: 95%;
    margin-top: 25%;
  }
  @media all and (max-width: 320px) {
    margin-top: 25%;
    font-size: 30px;
    width: 100%;
  }
`;

// Mission style
const Mission = styled.section`
  padding: 5% 0;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 920px) {
    flex-direction: column;
  }
`;
const MissionContent = styled.div`
  position: relative;
  width: 40%;
  p {
    font-family: ${({ language }) =>
      language === "ar" ? "cairoregular" : "inherit"};
    text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
    width: 300px;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-image: linear-gradient(
      27deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(37, 37, 41, 0.43461134453781514) 2%,
      rgba(0, 0, 0, 1) 100%
    );
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  @media all and (max-width: 920px) {
    width: 100%;
    margin-bottom: 4%;
  }
`;
const MissionPlus = styled.div`
  position: absolute;
  top: -15px;
  right: 15px;
`;
const Discover = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  a {
    display: block;
    width: 50%;
  }
  a:nth-child(2) {
    color: #000;
  }
  @media all and (max-width: 920px) {
    width: 100%;
  }
  @media all and (max-width: 520px) {
    flex-direction: column;
    a {
      width: 100%;
    }
  }
`;
const Portfolio = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
  background: #000;
  color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 5px;
  padding: 20px 20px 15px;
  &:hover {
    opacity: 0.9;
    transition: all 0.9s;
  }
  @media all and (max-width: 520px) {
    width: 100%;
  }
`;
const Product = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
  padding: 20px 20px 15px;
  background: linear-gradient(
    146deg,
    rgba(255, 255, 255, 1) 62%,
    rgba(254, 255, 199, 1) 100%
  );
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 5px;

  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 5%);
  backdrop-filter: blur(14.5px);
  -webkit-backdrop-filter: blur(14.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  &:hover {
    opacity: 0.9;
    transition: all 0.9s;
  }
  @media all and (max-width: 520px) {
    width: 100%;
  }
`;
const PortfolioHeading = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  font-size: 25px;
`;
const DiscoverCaption = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
`;
const PortfolioArrow = styled.div`
  position: absolute;
  filter: invert(1);
  top: 15px;
  right: 15px;
`;
const ProductHeading = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  font-size: 25px;
  font-weight: bold;
  font-size: 24px;
  margin: 10px 0;
`;
const ProductArrow = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;
// Specialized style
const Specialized = styled.section`
  display: flex;
  width: 80%;
  padding: 5%;
  margin: 0 auto;
  @media all and (max-width: 680px) {
    flex-direction: column;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const SpecializedHeading = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-left: ${({ language }) => (language === "ar" ? "auto" : "0")};
  width: 30%;
  font-weight: bold;
  font-size: 35px;
  line-height: 1.4em;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    bottom: -60px;
    left: -60px;
    border-radius: 200px;
    width: 130px;
    height: 130px;
    background: linear-gradient(
      127deg,
      rgba(251, 210, 210, 1) 20%,
      rgba(255, 255, 255, 1) 92%
    );
    -webkit-box-shadow: -80px -63px 67px 9px rgba(252, 234, 234, 1);
    -moz-box-shadow: -80px -63px 67px 9px rgba(252, 234, 234, 1);
    box-shadow: -80px -63px 67px 9px rgba(252, 234, 234, 1);
    filter: blur(10px);
    z-index: -1;
  }
  @media all and (max-width: 1290px) {
    font-size: 30px;
  }
  @media all and (max-width: 1290px) {
    width: 35%;
    font-size: 30px;
  }
  @media all and (max-width: 960px) {
    width: 50%;
    font-size: 30px;
    &:before {
      bottom: 0px;
    }
  }
  @media all and (max-width: 680px) {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
    &:before {
      right: 20px;
    }
  }
  @media all and (max-width: 480px) {
    font-size: 25px;
    &:before {
      top: 10px;
      right: 100px;
    }
  }
`;
const SpecializedContent = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-left: ${({ language }) => (language === "ar" ? "auto" : "0")};
  width: 60%;
  line-height: 1.7em;

  @media all and (max-width: 960px) {
    width: 50%;
  }
  @media all and (max-width: 680px) {
    width: 100%;
    text-align: center;
  }
  @media all and (max-width: 480px) {
  }
`;
// Services style
const Services = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 3% 0;
  @media all and (max-width: 850px) {
    flex-direction: column;
  }
`;
const ServiceImage = styled.div`
  width: 30%;
  img {
    width: 100%;
  }
  @media all and (max-width: 850px) {
    display: none;
  }
`;
const ServiceContainer = styled.div`
  width: 67%;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 850px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const ServiceContent = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-left: ${({ language }) => (language === "ar" ? "auto" : "0")};
`;
const ServiceLeft = styled.div`
  margin-top: 15%;
  width: 40%;
  @media all and (max-width: 850px) {
    width: 49%;
  }

  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const ServiceRight = styled.div`
  width: 59%;
  @media all and (max-width: 850px) {
    width: 49%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const ServiceHeading = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-left: ${({ language }) => (language === "ar" ? "auto" : "0")};
  font-size: 25px;
  font-weight: bold;
  width: 185px;
  margin-bottom: 10px;
`;
const AppDevelopment = styled.div`
  margin-bottom: 4%;
  padding: 25px;
  padding-bottom: 80px;
  background: linear-gradient(
    90deg,
    rgba(247, 247, 247, 1) 55%,
    rgba(247, 237, 247, 1) 98%
  );
  border-top-left-radius: 35px;

  /* glass effect */
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  &:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);

    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  /* ends */
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const GraphicDesigning = styled.div`
  margin-bottom: 4%;
  padding: 25px;
  padding-bottom: 80px;
  background: linear-gradient(
    31deg,
    rgba(245, 224, 223, 1) 0%,
    rgba(247, 247, 247, 1) 40%
  );

  border-bottom-left-radius: 35px;
  /* glass effect */
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  &:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);

    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  /* ends */
`;
const WebDesigning = styled.div`
  width: 70%;
  margin-bottom: 2%;
  padding: 25px;
  background: linear-gradient(
    48deg,
    rgba(247, 247, 247, 1) 64%,
    rgba(247, 240, 224, 1) 100%
  );
  border-top-right-radius: 35px;
  /* glass effect */
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  &:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);

    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  /* ends */
  @media all and (max-width: 680px) {
    width: 90%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const WebDevelopment = styled.div`
  margin-bottom: 2%;
  padding: 25px;
  background: linear-gradient(
    80deg,
    rgba(228, 246, 230, 1) 0%,
    rgba(247, 247, 247, 1) 40%
  );
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  /* glass effect */
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  &:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);

    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  /* ends */
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const ErpSolutions = styled.div`
  margin-bottom: 4%;
  padding: 25px;
  background: linear-gradient(
    142deg,
    rgba(247, 247, 247, 1) 54%,
    rgba(228, 243, 247, 1) 100%
  );

  border-bottom-right-radius: 35px;
  /* glass effect */
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  &:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);

    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  /* ends */
`;

// our products style

// about us style
const AboutUs = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5% 0;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    top: 0px;
    left: 230px;
    border-radius: 50px;
    width: 120px;
    height: 120px;
    background: linear-gradient(
      56deg,
      rgb(163 247 184) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    -webkit-box-shadow: -106px -81px 109px 23px rgb(192 249 207);
    -moz-box-shadow: -106px -81px 109px 23px rgb(192 249 207);
    box-shadow: -106px -81px 109px 23px rgb(192 249 207);
    filter: blur(8px);
  }
  @media all and (max-width: 1045px) {
    &:before {
      left: 170px;
    }
  }
  @media all and (max-width: 980px) {
    &:before {
      left: 60px;
    }
    display: block;
  }
  @media all and (max-width: 768px) {
    &:before {
      left: 100px;
      top: 100px;
    }
  }
`;
const TimeAndMoney = styled.div`
  width: 30%;
  padding: 55px 25px;
  background: #000;
  color: #fff;
  border-top-left-radius: 35px;
  border-bottom-right-radius: 35px;
  position: relative;
  @media all and (max-width: 980px) {
    width: 100%;
    margin-bottom: 2%;
  }
`;
const TimeAndMoneyHeading = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-bottom: 4%;
  font-weight: bold;
  font-size: 30px;
`;
const TimeAndMoneyCaption = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  font-weight: bold;
  margin-bottom: 7%;
  @media all and (max-width: 980px) {
    margin-bottom: 4%;
  }
`;
const TimeAndMoneyContent = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "poppinsregular"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-left: ${({ language }) => (language === "ar" ? "auto" : "0")};

  width: 80%;
  line-height: 1.5em;
  font-weight: lighter;
  padding: 25px;
  position: relative;
  &:before {
    top: 0;
    /* left: 0; */
    left: ${({ language }) => (language === "ar" ? "unset" : "0")};
    right: ${({ language }) => (language === "ar" ? "0" : "unset")};
    position: absolute;
    filter: invert(1);
    content: url(images/invertedcomma.svg);
  }
  @media all and (max-width: 1098px) {
    width: 100%;
  }
`;
const ContentAuthor = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  font-weight: bold;
  position: absolute;
  bottom: 35px;
  /* right: 35px; */
  left: ${({ language }) => (language === "ar" ? "unset" : "35px")};
  right: ${({ language }) => (language === "ar" ? "35px" : "unset")};
`;
const Reliable = styled.div`
  width: 60%;
  margin-right: auto;
  padding: 0 5%;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const ReliableHeading = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  font-weight: bold;
  font-size: 35px;
  padding: 15px 0;
  line-height: 1.5em;
  margin-bottom: 5%;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    top: 0px;
    right: 40px;
    border-radius: 50px;
    width: 200px;
    height: 200px;
    background: linear-gradient(
      56deg,
      rgba(255, 255, 255, 1) 31%,
      rgb(92 183 200) 79%
    );
    -webkit-box-shadow: 37px -129px 67px 9px rgba(228, 244, 247, 0.72);
    -moz-box-shadow: 37px -129px 67px 9px rgba(228, 244, 247, 0.72);
    box-shadow: 37px -129px 67px 9px rgba(228, 244, 247, 0.72);
    filter: blur(100px);
    z-index: -1;
  }
  @media all and (max-width: 980px) {
    right: 0px;
  }
  @media all and (max-width: 768px) {
    font-size: 30px;
  }
  @media all and (max-width: 640px) {
    font-size: 25px;
  }
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`;
const ReliablePointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const ReliablePoints = styled.div`
  display: flex;
  width: 49%;
  justify-content: space-between;
  margin-bottom: 4%;
  flex-direction: ${({ language }) =>
    language === "ar" ? "row-reverse" : "row"};
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const PointNumber = styled.div`
  width: 50px;
  height: 50px;
  border: 2px solid #000;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #000;
    color: #fff;
  }
`;
const PointText = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  width: 70%;
  padding: 0 5%;
  margin-right: auto;
  line-height: 1.4em;
`;
// quote style
const Quotes = styled.div`
  display: flex;
  /* position: relative;
  &:after {
    position: absolute;
    width: 100%;
    height: 500px;
    background: #000;
    bottom: 0;
  } */
  @media all and (max-width: 980px) {
    position: relative;
    flex-direction: column;
    padding-bottom: 20%;
  }
  @media all and (max-width: 768px) {
    padding-bottom: 22%;
  }
  @media all and (max-width: 730px) {
    padding-bottom: 25%;
  }
  @media all and (max-width: 640px) {
    padding-bottom: 30%;
  }
  @media all and (max-width: 540px) {
    padding-bottom: 35%;
  }
`;
const QuoteLeft = styled.div`
  position: relative;
  width: 49%;
  padding: 5%;
  padding-top: 0;
  padding-right: 15%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;

  @media all and (max-width: 980px) {
    position: unset;
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 4%;
  }
  @media all and (max-width: 480px) {
    line-height: 1.3em;
    margin-bottom: 5%;
  }
`;
const QuoteLeftContainer = styled.div`
  position: relative;
  &:before {
    position: absolute;
    content: "";
    bottom: -40px;
    right: 0px;
    left: 0px;
    border-radius: 250px;
    width: 250px;
    height: 250px;
    background: linear-gradient(
      291deg,
      rgba(213, 207, 245, 1) 20%,
      rgba(255, 255, 255, 1) 92%
    );
    z-index: -1;
    -webkit-box-shadow: -130px -106px 67px 9px rgba(241, 239, 251, 1);
    -moz-box-shadow: -130px -106px 67px 9px rgba(241, 239, 251, 1);
    box-shadow: -130px -106px 67px 9px rgba(241, 239, 251, 1);
    filter: blur(40px);
  }
  @media all and (max-width: 980px) {
    &:before {
      left: unset;
    }
  }
`;
const QuoteText = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  margin-bottom: 10%;

  @media all and (max-width: 980px) {
    margin-bottom: 2%;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 8%;
  }
`;
const GetInTouch = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background: #000;
  color: #fff;
  padding: 25px;
  padding-bottom: 15px;
  width: 280px;
  border-top-left-radius: 25px;

  @media all and (max-width: 480px) {
    padding: 15px;
    width: 200px;
  }
`;
const GetInTouchHeading = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  margin-bottom: 45px;
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  @media all and (max-width: 480px) {
    margin-bottom: 15px;
  }
`;
const GetInTouchCaption = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  font-size: 15px;
`;
const QuoteRight = styled.div`
  display: flex;
  width: 49%;
  justify-content: space-between;
  @media all and (max-width: 980px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const QuoteContainerLeft = styled.div`
  width: 39%;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const QuoteContentOne = styled.div`
  position: relative;
  margin-bottom: 4%;
  padding: 25px;
  padding-top: 65px;
  padding-bottom: 80px;
  background: linear-gradient(
    144deg,
    rgba(230, 239, 246, 1) 20%,
    rgba(247, 247, 247, 1) 94%
  );
  border-top-left-radius: 35px;
  div::before {
    top: 40px;
    left: 25px;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const QuoteContentText = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  font-weight: bold;
  line-height: 1.5em;
  margin-bottom: 25px;

  &:before {
    top: 20px;
    /* left: 25px; */
    left: ${({ language }) => (language === "ar" ? "unset" : "25px")};
    right: ${({ language }) => (language === "ar" ? "25px" : "unset")};
    position: absolute;
    content: url(images/invertedcomma.svg);
  }
`;
const QuoteContentAuthor = styled.div`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  position: absolute;
  bottom: 25px;
  /* right: 25px; */
  right: ${({ language }) => (language === "ar" ? "unset" : "25px")};
  left: ${({ language }) => (language === "ar" ? "25px" : "unset")};
`;
const QuoteContentTwo = styled.div`
  position: relative;
  margin-bottom: 4%;
  padding: 25px;
  padding-top: 45px;
  padding-bottom: 80px;
  background: linear-gradient(
    231deg,
    rgba(247, 247, 217, 1) 20%,
    rgba(247, 247, 247, 1) 56%
  );

  div:first-child {
    font-size: 12px;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    div:first-child {
      font-size: inherit;
    }
  }
`;
const QuoteContentThree = styled.div`
  position: relative;
  margin-bottom: 4%;
  padding: 25px;
  padding-top: 45px;
  padding-bottom: 80px;
  background: linear-gradient(
    36deg,
    rgba(245, 230, 230, 1) 20%,
    rgba(247, 247, 247, 1) 68%
  );
  border-bottom-right-radius: 35px;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const QuoteContainerRight = styled.div`
  width: 59%;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const QuoteContentFour = styled.div`
  position: relative;
  margin-bottom: 4%;
  padding: 25px;
  padding-top: 45px;
  padding-bottom: 80px;
  background: #f7f7f7;
  border-top-right-radius: 35px;
  div:first-child {
    font-size: 12px;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    div:first-child {
      font-size: inherit;
    }
  }
`;
const QuoteImage = styled.div`
  img {
    width: 100%;
  }
  margin-bottom: 4%;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const QuoteContentFive = styled.div`
  position: relative;
  margin-bottom: 4%;
  padding: 25px;
  padding-top: 45px;
  padding-bottom: 80px;
  background: #f7f7f7;
  border-bottom-right-radius: 35px;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const TryNowButton = styled.a`
  background: #27a162;
  cursor: pointer;
  padding: 10px 25px;
  border-radius: 50px;
  color: #fff;
  width: 120px;
  margin-top: 15px;
  min-width: 105px;
  text-align: center;
  font-weight: bold;
  display: block;
`;
const ProductHightLight = styled.div`
  padding: 50px 0;
`;
const ProductContainer = styled.div`
  display: flex;
  @media all and (max-width: 640px) {
    display: block;
  }
`;
const ProductImage = styled.img`
  width: 48%;
  height: 100%;
  margin-right: 4%;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  @media all and (max-width: 640px) {
    width: 100%;
    margin-bottom: 20px;
    border-top-left-radius: initial;
    border-bottom-right-radius: initial;
  }
`;
const ProductDescription = styled.p``;
const ProductLogo = styled.img`
  width: 250px;
`;
const ProductContent = styled.p`
  width: 500px;
  font-size: 16px;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
export default Home;
