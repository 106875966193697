import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

const CareerDescription = (props) => {
  const [t, i18n] = useTranslation("common");

  let data = {
    designation: "",
    experience: "",
    description: "",
  };
  if (props.career_datas.length && props.id > 0) {
    data = props.career_datas.filter((i) => i.id === props.id)[0];
  }
  // console.log(data);
  return (
    <ModalContainer show={props.show}>
      <Container>
        <Heading>{data.designation}</Heading>
        <Caption>
          Experience: <span>{data.experience}</span>
        </Caption>
        <Description>{data.description}</Description>
        <ButtonGroup>
          <Button
            className="cancel"
            onClick={() =>
              props.setCareerDescription({ show: false, id: props.id })
            }
          >
            {t("Cancel")}
          </Button>
          <Button
            className="apply"
            onClick={() => props.setCareerForm({ show: true, id: props.id })}
          >
            {t("Apply")}
          </Button>
        </ButtonGroup>
      </Container>
    </ModalContainer>
  );
};

export default CareerDescription;

const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin: auto;
  transition: all 0.3s ease-in-out;
  ${({ show }) =>
    show &&
    `
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.5); 
    background-attachment: fixed !important;
  `}

`;

const Container = styled.div`
  width: 500px;
  max-height:470px;
  overflow-y:scroll;
  height: fit-content;
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;

  z-index: 10;
  margin: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`;
const Heading = styled.p`
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
`;
const Caption = styled.p`
  font-size: 14px;
  width: 100%;
  margin-bottom: 5px;
  span {
    color: #727272;
  }
`;
const Description = styled.p`
  font-size: 14px;
  white-space: pre-line;
`;
const ButtonGroup = styled.div`
  margin-top: 25px;
  text-align: right;
`;
const Button = styled.button`
  border: 0;
  padding: 10px 25px;
  color: #fff;
  background: #030303;
  border-radius: 3px;
  cursor: pointer;
  &.cancel {
    margin-right: 8px;
  }
  &.apply {
    background: #0d4d29;
  }
`;
