import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import DownloadButton from "../components/DownloadButton";

const Products = (props) => {
  const [t, i18n] = useTranslation("common");
  let language = i18n.language;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <OurProducts id="products">
        <OurProductHeading language={language}>
          {t("Our Products")}
        </OurProductHeading>
        <Description>
          {t(
            "By Combining People,Creativity,And Technology, We Help Deliver The Most Innovative Solutions For Business. No Matter Which Service You Choose, The idea Behind Our Approach Is Always The Same - To Help You Simplify The Process Of Getting Your Dreams into Reality"
          )}
        </Description>
        <ViknProduct>
          <ViknErpContainer>
            <ViknErp>
              <ViknErpLogo language={language}>
                <img src="images/viknerp.png" alt="viknerp" />
                <p>
                  {t(
                    "Vikn ERP Transform the way you do business, it is an accounting software that enables you to simplify those everyday chores you have in your business that takes way too much time. This software is designed for any user. A successful ERP implementation requires solid accounting software functionality. Financial Force ERP accounting software eliminates any boundaries between various departments by offering a unified ERP solution, natively built on the Sales force platform."
                  )}
                </p>
                {/* <LearnMore language={language}>
                  <a href="!#" rel="noreferrer">
                    <p>{t("Learn More")}</p>
                    <img src="images/cursorarrow.svg" alt="cursorarrow" />
                  </a>
                </LearnMore> */}
                <DownloadLabel>{t("Download")}</DownloadLabel>
                <DownloadButton
                  text="For Windows"
                  image="images/platform/windows.svg"
                />
              </ViknErpLogo>
            </ViknErp>
            <ViknErpWork>
              <img src="images/laptop3.png" alt="laptop" />
            </ViknErpWork>
          </ViknErpContainer>
          <ViknbooksContainer>
            <ViknbooksWork>
              <img src="images/laptop2.png" alt="laptop" />
            </ViknbooksWork>
            <ViknBooks>
              <ViknbooksLogo language={language}>
                <img src="images/viknbooks.svg" alt="viknbooks" />
                <p>
                  {t(
                    "Viknbooks is an enterprise resource planning(ERP) software that is secure and provides solutions that deploy, scale and extent easily without sacrificing dependability. It is simple to implement, Manage and Upgrade, all with a modern platform and user experience."
                  )}
                </p>
                {/* <LearnMore language={language}>
                  <a
                    href="https://www.viknbooks.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>{t("Learn More")}</p>
                    <img src="images/cursorarrow.svg" alt="cursorarrow" />
                  </a>
                </LearnMore> */}
                <TryNowButton href="https://viknbooks.com/" target="_blank">
                  {t("Try Now")}
                </TryNowButton>
              </ViknbooksLogo>
            </ViknBooks>
          </ViknbooksContainer>
        </ViknProduct>
        <ViknProduct>
          <ViknErpContainer>
            <ViknErp>
              <ViknErpLogo language={language}>
                <img src="images/viknbooks-mobile.svg" alt="viknerp" />
                <p>
                  {t(
                    "Easily manages income, expenses, and more. Organized and in one place List and manage your products across multiple channels to increase brand awareness and grow your customer base Seamless integration across all major e-commerce platforms ensures that product listings, orders, and data stay up-to-date"
                  )}
                </p>
                {/* <LearnMore language={language}>
                  <a href="!#" rel="noreferrer">
                    <p>{t("Learn More")}</p>
                    <img src="images/cursorarrow.svg" alt="cursorarrow" />
                  </a>
                </LearnMore> */}
                <DownloadLabel>{t("Download")}</DownloadLabel>
                <ButtonContainer>
                  <DownloadButton
                    link="https://play.google.com/store/apps/details?id=com.vikncodes.erp"
                    background="#27a162"
                    text="For Android"
                    image="images/platform/android.svg"
                  />
                  <DownloadButton
                    link="https://apps.apple.com/cn/app/viknbooks/id1605753568"
                    background="#171717"
                    text="For IOS"
                    image="images/platform/apple.svg"
                  />
                </ButtonContainer>
              </ViknErpLogo>
            </ViknErp>
            <ViknErpWork>
              <img src="images/rassasy-new.png" alt="laptop" />
            </ViknErpWork>
          </ViknErpContainer>
          <ViknbooksContainer>
            <ViknbooksWork>
              <img src="images/viknbooks-mobile-image.png" alt="laptop" />
            </ViknbooksWork>
            <ViknBooks>
              <ViknbooksLogo language={language}>
                <img
                  className="logo"
                  src="images/rassasylogo.svg"
                  alt="viknbooks"
                />
                <p>
                  {t(
                    "Rassasy's Intuitive User interface elevates the user experience and makes billing fast and Intuitive. it is a feature-rich restaurant POS while being exceptionally user-friendly and eye-catching at the same time."
                  )}
                </p>
                {/* <LearnMore language={language}>
                  <a
                    href="https://www.viknbooks.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>{t("Learn More")}</p>
                    <img src="images/cursorarrow.svg" alt="cursorarrow" />
                  </a>
                </LearnMore> */}
                <DownloadLabel>{t("Download")}</DownloadLabel>
                <ButtonContainer>
                  <DownloadButton
                    background="#27a162"
                    text="For Android"
                    image="images/platform/android.svg"
                    link="https://play.google.com/store/apps/details?id=com.rassasy.erp"
                  />
                  <DownloadButton
                    text="For Windows"
                    image="images/platform/windows.svg"
                  />
                </ButtonContainer>
              </ViknbooksLogo>
            </ViknBooks>
          </ViknbooksContainer>
        </ViknProduct>
        <ViknProduct>
          <ViknErpContainer>
            <ViknErp>
              <ViknErpLogo language={language}>
                <img
                  className="logo"
                  src="images/kassalogo.svg"
                  alt="viknerp"
                />
                <p>
                  {t(
                    "Kassa system automates and join several retail processes to ultimately reduce the time your customers and employees spend processing financial transactions."
                  )}
                </p>
                {/* <LearnMore language={language}>
                  <a href="!#" rel="noreferrer">
                    <p>{t("Learn More")}</p>
                    <img src="images/cursorarrow.svg" alt="cursorarrow" />
                  </a>
                </LearnMore> */}
                <DownloadLabel>{t("Download")}</DownloadLabel>
                <DownloadButton
                  text="For Windows"
                  image="images/platform/windows.svg"
                />
              </ViknErpLogo>
            </ViknErp>
            <ViknErpWork>
              <img src="images/faera.png" alt="laptop" />
            </ViknErpWork>
          </ViknErpContainer>
          <ViknbooksContainer>
            <ViknbooksWork>
              <img src="images/kassa.png" alt="laptop" />
            </ViknbooksWork>
            <ViknBooks>
              <ViknbooksLogo language={language}>
                <img className="logo" src="images/faera.svg" alt="viknbooks" />
                <p>
                  {t(
                    "Faera is a type of point of sale system that allows you to simplify and automate sales and checkout processes. These tools come in handy for supermarkets and grocery stores that operate on multiple checkout locations."
                  )}
                </p>
                {/* <LearnMore language={language}>
                  <a
                    href="https://www.viknbooks.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p>{t("Learn More")}</p>
                    <img src="images/cursorarrow.svg" alt="cursorarrow" />
                  </a>
                </LearnMore> */}
                <DownloadLabel>{t("Download")}</DownloadLabel>
                <DownloadButton
                  text="For Windows"
                  image="images/platform/windows.svg"
                />
              </ViknbooksLogo>
            </ViknBooks>
          </ViknbooksContainer>
        </ViknProduct>
        <Rassasy>
          <RassasyLogo language={language}>
            <RassasyContent>
              <img className="logo" src="images/dubalogo.svg" alt="rassasy" />
              <p>
                {t(
                  "Duba Sales is an Add-on to the ViknERP Software available for Windows that lets you sell products offline on the fly without an internet connection, later syncs with the desktop app whenever the user chooses to. It also lets you create and print invoices/Receipts on the go by connecting to wireless printers. Also, get your daily reports right on your mobile screen. Take the first step for your journey with us."
                )}
              </p>
              {/* <LearnMore language={language}>
                <a
                  href="https://rassasy.vikncodes.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <p>{t("Learn More")}</p>
                  <img src="images/cursorarrow.svg" alt="cursorarrow" />
                </a>
              </LearnMore> */}
              <DownloadLabel>{t("Download")}</DownloadLabel>
              <ButtonContainer>
                <DownloadButton
                  background="#27a162"
                  text="For Android"
                  image="images/platform/android.svg"
                  link="https://play.google.com/store/apps/details?id=com.vikncodes.vansaleapplication"
                />
                <DownloadButton
                  text="For Windows"
                  image="images/platform/windows.svg"
                />
              </ButtonContainer>
            </RassasyContent>
          </RassasyLogo>
          <RassasyWork>
            <img src="images/duba.png" alt="laptop" />
          </RassasyWork>
        </Rassasy>
      </OurProducts>
    </Container>
  );
};

const Container = styled.main`
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
  }
`;
// our products style
const OurProducts = styled.section`
  padding: 5% 0;
  img {
    border-radius: inherit;
  }
  @media all and (max-width: 480px) {
    padding-top: 25%;
  }
`;
const OurProductHeading = styled.p`
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1%;
  @media all and (max-width: 480px) {
    font-size: 30px;
  }
`;
const ViknProduct = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
  @media all and (max-width: 829px) {
    display: block;
  }
`;
const ViknErpContainer = styled.div`
  width: 49%;
  @media all and (max-width: 829px) {
    width: 100%;
  }
`;
const ViknErp = styled.div`
  margin-bottom: 5%;
`;
const ViknErpLogo = styled.div`
  .logo {
    width: 45%;
  }
  padding: 15px;
  width: 80%;
  margin-left: auto;
  margin-bottom: 1%;
  position: relative;
  background: linear-gradient(
    29deg,
    rgba(255, 255, 255, 0.6418942577030813) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(235, 248, 254, 1) 100%
  );
  img {
    margin-bottom: 3%;
  }
  @media all and (max-width: 829px) {
    width: 100%;
    margin: 0 auto;
  }
  @media all and (max-width: 480px) {
    img:first-child {
      width: 50%;
    }
  }
`;
const LearnMore = styled.div`
  p {
    margin-right: 10px;
  }
  position: absolute;
  top: 10px;
  right: 10px;
  a {
    color: #000;
    display: flex;
    justify-content: space-between;
  }
`;

const ViknErpWork = styled.div`
  border-radius: 40px;
  margin-left: auto;
  width: 80%;
  img {
    width: 100%;
  }
  @media all and (max-width: 829px) {
    display: none;
  }
`;
const RassasyWork = styled.div`
  border-radius: 40px;
  width: 49%;
  /* margin-right: auto; */
  img {
    width: 80%;
  }
  @media all and (max-width: 829px) {
    display: none;
  }
`;
const ViknbooksContainer = styled.div`
  width: 49%;
  @media all and (max-width: 829px) {
    width: 100%;
  }
`;
const ViknbooksWork = styled.div`
  border-radius: 40px;
  width: 80%;
  img {
    width: 100%;
  }
  margin-bottom: 5%;
  @media all and (max-width: 829px) {
    display: none;
  }
`;
const ViknBooks = styled.div``;
const ViknbooksLogo = styled.div`
  .logo {
    width: 45%;
  }
  padding: 15px;
  width: 80%;
  margin-right: auto;
  position: relative;
  background: linear-gradient(
    29deg,
    rgba(235, 248, 254, 1) 0%,
    rgba(255, 255, 255, 1) 45%,
    rgba(255, 255, 255, 0.6418942577030813) 100%
  );
  img {
    margin-bottom: 3%;
  }
  @media all and (max-width: 829px) {
    width: 100%;
    margin: 0 auto;
  }
  @media all and (max-width: 480px) {
    background: linear-gradient(
      29deg,
      rgba(255, 255, 255, 0.6418942577030813) 0%,
      rgba(255, 255, 255, 1) 55%,
      rgba(235, 248, 254, 1) 100%
    );
    img:first-child {
      width: 50%;
    }
  }
`;
const Rassasy = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 829px) {
    display: block;
  }
`;
const RassasyLogo = styled.div`
  width: 49%;
  background: linear-gradient(
    29deg,
    rgba(255, 255, 255, 0.6418942577030813) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(235, 248, 254, 1) 100%
  );
  @media all and (max-width: 829px) {
    width: 100%;
  }
`;
const RassasyContent = styled.div`
  padding: 15px;
  position: relative;
  width: 80%;
  margin-left: auto;
  img {
    margin-bottom: 3%;
  }
  .logo {
    width: 45%;
  }
  @media all and (max-width: 829px) {
    width: 100%;
    margin: 0 auto;
  }
  @media all and (max-width: 480px) {
    img:first-child {
      width: 50%;
    }
  }
`;
const Description = styled.p`
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  margin-bottom: 40px;
`;
const DownloadLabel = styled.label`
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  margin-top: 20px;
`;

const TryNowButton = styled.a`
  background: #27a162;
  cursor: pointer;
  padding: 15px 25px;
  border-radius: 50px;
  color: #fff;
  width: 120px;
  margin-top: 15px;
  min-width: 105px;
  text-align: center;
  font-weight: bold;
  display: block;
  @media all and (max-width: 460px) {
    padding: 8px 10px;
    width: 105px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 305px;
  justify-content: space-between;
  margin-top: 15px;
`;
export default Products;
