import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const About = (props) => {
  const [t, i18n] = useTranslation("common");
  let language = i18n.language;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <AboutUs>
        <AboutLeft>
          <WeAreVikncodes>
            <AboutUsCaption language={language}>{t("About Us")}</AboutUsCaption>
            <AboutUsHeading language={language}>
              {t("We are Vikn Codes")}
            </AboutUsHeading>
            <AboutUsContent language={language}>
              {t(
                "Our Values Shapes The Way We Work With Our Client, Delivering Outstanding User Experience Supported By Cutting-Edge Technologies"
              )}
            </AboutUsContent>
          </WeAreVikncodes>
          <CloseCommunication>
            <CloseCommunicationHeading language={language}>
              {t("Close Communication With Team")}
            </CloseCommunicationHeading>
            <CloseCommunicationContent language={language}>
              {t(
                "A Small Team, Which Gives The Least Time For Communication And Coordination. Close Communication For In-Depth Product Exploration. We Close The Entire Cycle From The Technical Task To Its Deployment."
              )}
            </CloseCommunicationContent>
          </CloseCommunication>
        </AboutLeft>
        <AboutRight>
          <AboutQuoteOne>
            <AboutQuoteHeading language={language}>
              {t("We Make A Qualitative Assessment")}
            </AboutQuoteHeading>
            <AboutQuoteContent language={language}>
              {t(
                "Thanks To Our Narrow Specialization, We Evaluate The Work On The Project In Detail Andaccurately. We Break Down And Schedule All Tasks By The Hour And Provide Reporting.You Will Clearly See The Path To The Final Product, As Well As What You Will Spend Your Money On And What You Can Save On."
              )}
            </AboutQuoteContent>
          </AboutQuoteOne>
          <AboutQuoteTwo>
            <AboutQuoteHeading language={language}>
              {t("We Take Into Work The Existing Projects")}
            </AboutQuoteHeading>
            <AboutQuoteContent language={language}>
              {t(
                "We Take Into Work The Existing Projects Of The Customer And Support Them, Regardless Of The Technology Stack Used. We Are Interested In Your Good Sales, Since Most Likely You Will Come To Us For The Implementation Of New Ideas."
              )}
            </AboutQuoteContent>
          </AboutQuoteTwo>
          <AboutQuoteThree>
            <AboutQuoteHeading language={language}>
              {t("Regular Visible Deliverables")}
            </AboutQuoteHeading>
            <AboutQuoteContent language={language}>
              {t(
                "Thanks To Agile Development, We Divide Work Into Sprints. Closing Each Sprint With The Consent Of The Customer, Thereby We Guarantee Each Time A Part Of The Work Completed That Satisfies The Client."
              )}
            </AboutQuoteContent>
          </AboutQuoteThree>
        </AboutRight>
      </AboutUs>
    </Container>
  );
};

const Container = styled.main`
  padding-top: 5%;
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
`;
const AboutUs = styled.section`
  display: flex;
  justify-content: space-between;
  padding-top: 3%;
  @media all and (max-width: 980px) {
    flex-direction: column;
  }
  @media all and (max-width: 480px) {
    padding-bottom: 4%;
  }
`;
const AboutLeft = styled.div`
  width: 39%;
  padding-bottom: 15%;
  @media all and (max-width: 980px) {
    padding-bottom: 0%;
    margin-bottom: 4%;
    width: 100%;
  }
`;
const WeAreVikncodes = styled.div`
  margin-bottom: 8%;
`;
const AboutUsCaption = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  font-size: 20px;
  font-weight: bold;
`;
const AboutUsHeading = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  font-size: 40px;
  font-weight: bold;
  @media all and (max-width: 1043px) {
    line-height: 1.2em;
  }
  @media all and (max-width: 480px) {
    font-size: 30px;
  }
`;
const AboutUsContent = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  line-height: 1.5em;
`;
const CloseCommunication = styled.div`
  background: #000;
  padding: 5%;
  color: #fff;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  width: 70%;
  margin-left: auto;
  @media all and (max-width: 1080px) {
    width: 80%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const CloseCommunicationHeading = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2em;
  margin-bottom: 4%;
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`;
const CloseCommunicationContent = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
`;
const AboutRight = styled.div`
  width: 59%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media all and (max-width: 980px) {
    flex-direction: column;
    width: 100%;
  }
  @media all and (max-width: 600px) {
    margin-bottom: 5%;
  }
`;
const AboutQuoteOne = styled.div`
  width: 49%;
  margin-bottom: 4%;
  padding: 25px;
  padding-bottom: 80px;
  padding-top: 80px;
  background: linear-gradient(
    36deg,
    rgba(226, 235, 248, 1) 5%,
    rgba(246, 247, 250, 1) 38%,
    rgba(250, 250, 250, 1) 71%,
    rgba(245, 225, 250, 1) 96%
  );
  border-radius: 35px;
  @media all and (max-width: 980px) {
    padding-top: 25px;
    margin-bottom: 4%;
    width: 100%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const AboutQuoteHeading = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  font-size: 30px;
  line-height: 1.3em;
  font-weight: bold;
  margin-bottom: 4%;
  @media all and (max-width: 480px) {
    font-size: 20px;
  }
`;
const AboutQuoteContent = styled.p`
  font-family: ${({ language }) =>
    language === "ar" ? "cairoregular" : "inherit"};
  text-align: ${({ language }) => (language === "ar" ? "right" : "left")};
  line-height: 1.6em;
`;
const AboutQuoteTwo = styled.div`
  width: 49%;
  margin-bottom: 4%;
  margin-top: 80px;
  padding: 25px;
  padding-bottom: 80px;
  background: linear-gradient(
    140deg,
    rgba(211, 242, 237, 1) 5%,
    rgba(246, 247, 250, 1) 29%,
    rgba(250, 250, 250, 1) 69%,
    rgba(214, 245, 212, 1) 90%
  );
  border-radius: 35px;
  @media all and (max-width: 980px) {
    margin-top: 0;
    width: 100%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const AboutQuoteThree = styled.div`
  width: 60%;
  margin-bottom: 15%;
  padding: 25px;
  padding-bottom: 80px;
  background: linear-gradient(
    36deg,
    rgba(242, 242, 211, 1) 5%,
    rgba(246, 247, 250, 1) 29%,
    rgba(250, 250, 250, 1) 69%,
    rgba(249, 223, 222, 1) 90%
  );
  border-radius: 35px;
  @media all and (max-width: 980px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
export default About;
