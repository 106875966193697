// export const URL = "http://192.168.1.20:8000/";
// export const BASE_URL = "http://192.168.1.20:8000/api/v1";
// export const MEDIA_URL = "http://192.168.1.20:8000";

// export const URL = "http://192.168.1.67:8000/";
// export const BASE_URL = "http://192.168.1.67:8000/api/v1";
// export const MEDIA_URL = "http://192.168.1.67:8000";

export const URL = "https://api.vikncodes.com/";
export const BASE_URL = "https://api.vikncodes.com/api/v1";
export const MEDIA_URL = "https://api.vikncodes.com";
